<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>ตั้งค่า ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข</strong>
    </div>
    <div class="card">

      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroupId===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="p-2">

        <div class="text-danger">*** ตั้งค่าสมาชิกทั้งหมด</div>

        <table class="table table-hover table-bordered mb-2">
          <thead class="alert-warning">
            <tr>
              <th class="text-center">#</th>
              <th width="12%" class="text-center">3 ตัวบน</th>
              <th width="12%" class="text-center">3 ตัวโต๊ด</th>
              <th width="12%" class="text-center">2 ตัวบน</th>
              <th width="12%" class="text-center">2 ตัวล่าง</th>
              <th width="12%" class="text-center">วิ่งบน</th>
              <th width="12%" class="text-center">วิ่งล่าง</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center p-1">ขั้นต่ำ</td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTop.min"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTode.min"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberTop.min"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberBottom.min"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runTop.min"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runBottom.min"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <td class="text-center p-1">สูงสุด</td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTop.max"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTode.max"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberTop.max"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberBottom.max"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runTop.max"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runBottom.max"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <td class="text-center p-1">สูงสุดต่อเลข</td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTop.maxPerNumber"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.threeNumberTode.maxPerNumber"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberTop.maxPerNumber"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.twoNumberBottom.maxPerNumber"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runTop.maxPerNumber"
                >
                </b-form-input>
              </td>
              <td class="p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="input.runBottom.maxPerNumber"
                >
                </b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-right">
          <button class="btn btn-success" @click="save">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MarketService from '@/services/MarketService'
import Swal from 'sweetalert2'

export default {
  name: 'SettingBet',
  data() {
    return {
      data: null,
      tabActiveGroupId: null,
      input: {
        threeNumberTop: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        },
        threeNumberTode: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        },
        twoNumberTop: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        },
        twoNumberBottom: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        },
        runTop: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        },
        runBottom: {
          min: 0,
          max: 0,
          maxPerNumber: 0
        }
      }
    }
  },
  computed: {
    groups() {
      if(!this.data)
        return []

      return this.data.groups
    }
  },
  watch: {
    tabActiveGroupId() {
      if(this.tabActiveGroupId) {
        this.input = this.data.setGroups[this.tabActiveGroupId].betLimit
      }
    }
  },
  methods: {
    getSetting() {
      MarketService.getSettingGroups().then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.data = response.data

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.data.groups[0]?._id
          }else{
            this.input = this.data.setGroups[this.tabActiveGroupId].betLimit
          }
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    save() {

      Swal.fire({
        title: 'ยืนยัน!',
        text: "ตั้งค่าให้กับสมาชิกทั้งหมด",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return MarketService.saveSettingBetLimit(this.tabActiveGroupId, this.input)
          .then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((e) => {
            Swal.fire({
              title: 'ผิดพลาด!',
              text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.getSetting()
          })
        }
      })
    }
  },
  mounted() {
    this.getSetting()
  }
}
</script>
<style lang="scss" scoped>
table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
